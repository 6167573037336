export class ApprovalStepType {
    constructor() {
        this.approver_type = ""
        this.order_type = ""
        this.approver_ids = []
        this.statuses = []
        this.show = false
    }
}

export class ApprovalStepStatusType{
    constructor() {
        this.key = ""
        this.name = ""
        this.name_en = ""
        this.status = ""
    }
}