import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/approval/template', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/approval/template/' + id);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/approval/template', data);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/approval/template/' + id, formData);
}
const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/approval/template/' + id);
}

export default {
    getAll,
    get,
    store,
    update,
    del,
}
