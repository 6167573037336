<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('name')">
                        <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                            <b-form-input v-model="formData.name" :state="errors[0] ? false : null"
                                :disabled="formDisabled" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('name_en')">
                        <ValidationProvider name="name_en" rules="required" v-slot="{ valid, errors }">
                            <b-form-input v-model="formData.name_en" :state="errors[0] ? false : null"
                                :disabled="formDisabled" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('last_approve_date')">
                        <ValidationProvider name="last_approve_date" rules="required" v-slot="{ valid, errors }">
                            <select-date v-model="formData.last_approve_date" :validation-error="errors[0]" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                    <ValidationProvider name="model_type" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('model')">
                            <multiselect :class="errors[0] ? 'box-border-color' : ''" :options="systemModelList"
                                :placeholder="$t('field')" v-model="formData.model_type" :searchable="true"
                                @input="handleInput($event, false)" :disabled="formDisabled">
                            </multiselect>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="12" md="6">
                    <ValidationProvider name="admin_roles" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="`${$t('admin')} ${$t('roles')}`">
                            <role-selectbox :valueType="'name'" :multiple="true" v-model="formData.admin_roles"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="read_only_roles" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="`${$t('readonly')} ${$t('roles')}`">
                            <role-selectbox :valueType="'name'" :multiple="true" v-model="formData.read_only_roles"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="12" md="6">
                    <b-form-group :label="$t('explanation')">
                        <ValidationProvider name="description" rules="required" v-slot="{ valid, errors }">
                            <b-form-input v-model="formData.description" :state="errors[0] ? false : null"
                                :disabled="formDisabled" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>

            </b-row>
        </ValidationObserver>
        <div class="d-flex justify-content-end">
            <b-button variant="primary" @click="addApproveStep">
                {{ `${$t('approve_step')} ${$t('add')}` }}
            </b-button>
        </div>
        <template v-for="(approvalStep, index) in approvalSteps">
            <b-card no-body class="my-2" :key="`step-${index}`">
                <b-card-header header-tag="header" class="text-left p-1" role="tab">
                    <b-button block variant="outline-secondary secondary-border-light" class="text-left"
                        @click="handleCollapse(index)">
                        {{ `${$t('approve_step')} ${index + 1}` }}
                    </b-button>
                </b-card-header>
                <b-collapse :id="`step-${index}`" :accordion="`step-${index}`" v-model="approvalStep.show">
                    <b-card-body>
                        <ApprovalStep :ref="`approvalStep-${index}`" :value="approvalStep" :index="index"
                            :formType="formType" @updateValue="(e) => approvalSteps[index] = e" />
                        <div class="d-flex justify-content-between mt-3">
                            <b-button variant="primary" @click="stepStatusAdd(index)">
                                {{ `${$t('status')} ${$t('add')}` }}
                            </b-button>
                            <b-button variant="danger" @click="stepDelete(index)">
                                {{ `${$t('approve_step')} ${$t('delete')}` }}
                            </b-button>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </template>

        <div class="d-flex justify-content-end mt-3">
            <b-button :variant="formType === 'delete' ? 'danger' : 'primary'" @click="save" :disabled="formLoading">
                {{ okButtonText }}
            </b-button>
        </div>
    </div>
</template>

<script>
import RoleService from '@/services/RoleService'
import handleInput from "@/helpers/handleInput";

import ApprovalTemplateService from '@/services/ApprovalTemplateService'
import SystemService from '@/services/SystemService'

import { ApprovalStepType, ApprovalStepStatusType } from "../type/ApprovalStepType"

export default {
    name: "RoleFilterDataForm",
    components: {
        ApprovalStep: () => import("./ApprovalStep")
    },
    props: {
        formType: {
            type: String,
            default: ""
        },
        formId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            ApprovalStepType,
            handleInput,
            formData: {},
            roleOptions: [],
            formLoading: false,
            systemModelList: [],
            systemFields: [],
            approvalSteps: [],
        }
    },
    watch: {
        formType: {
            handler(val) {
                this.formData = {}
                if (val !== "create" && this.formId > 0) {
                    this.getFormData()
                }
                if (val === "create") {
                    this.approvalSteps = []
                }
            }
        },
    },
    computed: {
        formDisabled() {
            return ["delete", "show"].includes(this.formType)
        },
        okButtonText() {
            switch (this.formType) {
                case "create":
                    return this.$t('create')
                case "show":
                    return this.$t('close')
                case "update":
                    return this.$t('update')
                case "delete":
                    return this.$t('delete')
                default:
                    return this.$t('close')
            }
        }
    },
    methods: {
        tabChange(index) {
            this.tabIndex = index
        },
        async save() {
            let isValid = await this.$refs.formModalValidate.validate()
            if (!isValid && this.formType !== "delete") return
            if (this.approvalSteps.length < 1) {
                this.$toast.error(this.$t("approve_step_error"));
                return
            } else {
                for (let index = 0; index < this.approvalSteps.length; index++) {
                    const approvalStep = this.$refs[`approvalStep-${index}`][0]
                    const valid = await approvalStep.$refs["formApprovalStep"].validate()
                    if (!valid) {
                        if (!this.approvalSteps[index].show) {
                            this.approvalSteps[index].show = !this.approvalSteps[index].show
                        }
                        return
                    }
                }
            }

            let response = null;
            this.formLoading = true

            if (this.formType === "delete") {
                const result = await this.$swal.fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                if (!result.isConfirmed) {
                    return
                }
            }




            this.formData.approval_steps = this.approvalSteps.map(a => {
                const statuses = {}


                for (let index = 0; index < a.statuses.length; index++) {
                    const item = a.statuses[index];
                    statuses[item.key] = {
                        name: item.name,
                        name_en: item.name_en,
                        status: item.status,
                    }
                }
                const approvalData = {
                    approver_ids: a.approver_ids?.map(a => a.toString()),
                    approver_type: a.approver_type.value,
                    order_type: a.order_type.value,
                    statuses: statuses
                }
                if (a.filter) {
                    approvalData.filter = a.filter
                }
                return approvalData
            })

            switch (this.formType) {
                case 'create':
                    response = await ApprovalTemplateService.store(this.formData).catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);

                    })
                    break;
                case 'update':
                    response = await ApprovalTemplateService.update(this.formId, this.formData).catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    break;
                case 'delete':
                    response = await ApprovalTemplateService.del(this.formId).catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    break;
            }


            if (response.status === 200) {
                this.$toast.success(this.$t('api.' + response.data.message));
                this.formData = {}
                this.$emit('dataFormSuccess')
                this.$refs.formModalValidate.reset();
            }

            this.formLoading = false
        },

        getRoles() {
            this.roleOptions = []

            const config = {
                params: {
                    limit: -1
                }
            }

            RoleService.getAll(config)
                .then((response) => {
                    this.roleOptions = response?.data?.data?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                        ?.map((item) => {
                            return {
                                value: item.name,
                                text: this.getLocaleText(item, 'explanation')
                            }
                        }) ?? []

                })
                .catch((e) => {
                    this.showErrors(e)
                })
        },
        async getFormData() {
            const response = await ApprovalTemplateService.get(this.formId).catch(e => {
                this.showErrors(e);
            })
            if (response.status === 200) {

                this.formData = response.data.data
                this.approvalSteps = response.data.data.approval_steps.map(a => ({
                    ...a,
                    approver_type: {
                        text: this.$t(a.approver_type.toLowerCase()),
                        value: a.approver_type,
                    },
                    order_type: {
                        text: this.$t(a.order_type.toLowerCase()),
                        value: a.order_type,
                    },
                    approver_ids: (a.approver_ids?.map(a => Number(a))),
                    statuses: Object.keys(a.statuses).map(b => ({ ...a.statuses[b], key: b })),
                    show: true
                }))
            }
        },
        async getModelList() {
            const response = await SystemService.getModelList()
            if (response?.status === 200) {

                this.systemModelList = response.data.data
            }
        },
        addApproveStep() {
            const step = new ApprovalStepType()
            this.approvalSteps.push(step)
        },
        handleCollapse(index) {

            this.approvalSteps[index].show = !this.approvalSteps[index].show
        },
        async stepDelete(index) {
            const result = await this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
            if (result.isConfirmed) {
                this.approvalSteps.splice(index, 1)
            }
        },
        stepStatusAdd(index) {
            const status = new ApprovalStepStatusType()
            this.approvalSteps[index].statuses.push(status)
        }
    },
    created() {
        this.getRoles()
        this.getModelList()
    }
}
</script>
